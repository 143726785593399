<template>
  <div class="top_Header">
    <header class="headers">
      <img src="../../../public/image/1.png" alt="" />
      <img
        class="imgSrc"
        src="../../../public/image/yunshuju_logo.png"
        alt=""
      />
    </header>
    <section class="sects">
      <img src="../../assets/thankyou.png" alt="" />
    </section>

    <div class="bgphoto">
      <img src="../../assets/bg.png" alt="" />
    </div>

    <div class="exit" @click="exit">
      <button>退出</button>
    </div>

    <div class="footers">
      <img
        src="../../assets/中国汽车售后服务质量监测大数据平台 提供.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    exit() {
      alert("答题已结束");
      WeixinJSBridge.call("closeWindow");
    },
  },
  computed: {},
  watch: {},
  created() {
    document.body.removeChild(document.getElementById("Loading"));
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.top_Header {
  width: 100%;
  height: 100%;

  .headers {
    display: flex;
    margin: 0px 27px;
    margin-top: 30px;
    align-items: center;

    .imgSrc {
      margin-left: 9px;
    }
  }

  .sects {
    width: 100%;
    height: 100%;
    margin: 0px 27px 45px;
    margin-top: 60px;
  }

  .content {
    width: 100%;
    height: 100%;
    margin: 0px 27px 95px;
  }

  .bgphoto {
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -11;
    }
  }

  .exit {
    margin-top: 350px;

    button {
      width: 275px;
      height: 44px;
      background: #ffffff;
      border-radius: 28px;
      font-weight: 600;
      display: block;
      margin: 0px auto;
      border: none;
      color: #005ae9;
      letter-spacing: 1px;
      font-size: 16px;

      &:after {
        border: none;
      }
    }
  }

  .foters {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    p {
      width: 268px;
      height: 44px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      line-height: 22px;
      text-align: center;
    }
  }

  .footers {
    margin-top: 35px;

    img {
      display: block;
      margin: 0px auto;
    }
  }
}
</style>